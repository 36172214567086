import React, { Component } from 'react';

import { ContentExplorer } from 'box-ui-elements';
import messages from 'box-ui-elements/i18n/en-US';
import 'box-ui-elements/dist/explorer.css';

import Credentials from '../client-credentials/credentials';

import '../styles/editContent.css';

const CONTENT_FOLDER = Credentials.contentFolder;

export default class EditContent extends Component {
    constructor(props) {
        super(props);

        this.state = { 
            contentEntries: [],
            itemSelected: false,
            token: '',
            oldContentName: '',
            contentName: '',
            contentDesc: '',
            contentImg: '',
            contentLOB: 'Featured',
            folderLoading: false,
            folderReady: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleEditButton = this.handleEditButton.bind(this);
        this.checkSubmitStatus = this.checkSubmitStatus.bind(this);
        this.requestDownscopedToken = this.requestDownscopedToken.bind(this);
        this.getFolderSize = this.getFolderSize.bind(this);
        this.handleBackButton = this.handleBackButton.bind(this);
    }

    // Resolve the content initially from Redis to display in cards
    componentDidMount() {
        fetch('/api/resolveContent', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                contentFolderID: CONTENT_FOLDER
            })
          }).then((response) => {
              if(response.status === 200) {
                  const contentData = response.json();

                  contentData.then((content) => {
                      content.items.forEach((item) => {
                          this.setState({contentEntries: [...this.state.contentEntries, item]});
                      })
                  });
              }
          });
    }

    // Function to check the length of the required inputs
    checkInput(item) {
        if(item.length <= 0) {
            return false;
        }
        return true;
    }

    // Function to disable the submit button if the required fields are not filled out
    checkSubmitStatus() {
        let {contentName, contentDesc, contentImg} = this.state;
        if(contentName.length <= 0 || contentDesc.length <= 0 || contentImg.length <= 0) {
            return true;
        }
        return false;
    }

    // Function that updates the appropriate value to be sent and saved
    handleChange(e, field) {
        if(field === 'contentName') {
            this.setState({contentName: e.target.value})
        }
        else if(field === 'contentDesc') {
            this.setState({contentDesc: e.target.value})
        }
        else if(field === 'contentImg') {
            this.setState({contentImg: e.target.value})
        }
        else if(field === 'contentLOB'){
            this.setState({contentLOB: e.target.value})
        }
    }

    // API request to get token that will let us edit the Box content
    requestDownscopedToken(folderPath) {
        return new Promise((resolve) => {
            fetch('/api/exchangeToken', {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    folderPath: folderPath,
                    type: 'service'
                })
              }).then((response) => {
                  const tokenReceived = response.json();
                  tokenReceived.then((token) => {
                      resolve(token.newToken);
                })
            });
        })
    }

    // Set the state to the selected item and get a token for the UI element to edit it as service account
    handleEditButton(itemInfo) {
        this.setState({ 
            folderLoading: true,
            newFolderID: itemInfo.ID, 
            contentName: itemInfo.Name,
            contentDesc: itemInfo.Desc,
            contentLOB: itemInfo.LOB,
            contentImg: itemInfo.ImgURL,
            oldContentName: itemInfo.Name,
         });
        console.log(itemInfo);

        this.requestDownscopedToken(itemInfo.ID).then((token) => {
            this.setState({ 
                token: token, 
                itemSelected: true, 
                folderLoading: false, 
                folderReady: true, 
             });
        });
    }

    handleSubmit() {
        fetch('/api/editContent', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                contentOption: this.state.contentLOB,
                contentName: this.state.contentName,
                contentDesc: this.state.contentDesc,
                contentImg: this.state.contentImg,
                contentOldName: this.state.oldContentName,
                folderID: this.state.newFolderID
            })
          }).then((response) => {
              if(response.status === 200) {
                //   const contentData = response.json();
        
                //   contentData.then((content) => {
                //       if(content !== undefined) {
                //           console.log('edited ok')
                //       }
                //   });
              }
          });

    }

    handleBackButton() {
        this.setState({ itemSelected: false, folderReady: false });
    }

    // Function to get folder size after uploading has been done
    getFolderSize(e) {
        e.preventDefault();
        this.setState({ itemSelected: false, folderReady: false });

        // Get folder size and update it
        fetch('/api/getFolderSize', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                folderID: this.state.newFolderID,
                contentName: this.state.contentName
            })
          }).then((response) => {
              if(response.status === 200) {
                  return;
              }
              else {
                  return new Error('Issue with getting folder size in Edit Admin route');
              }
          });

          this.handleSubmit();
    }

    render() {
        return (
            <div id='editFormContainer'>
                {!this.state.itemSelected && (
                    <div className="editContainer">
                        <ul className="list-group list-group-flush">
                        {
                            this.state.contentEntries.map((item) => {
                                return(
                                    <li className="list-group-item editCard" key={item.Name}>
                                        <img className='refImage' src={item.ImgURL} alt='cardContent'></img> {item.Name} - <span>{item.LOB}</span>
                                        <button onClick={() => this.handleEditButton(item)} className='btn btn-outline-info float-right'>Edit</button>
                                    </li>
                                );
                            })
                        }
                        </ul>
                    </div>
                )}

        {this.state.folderReady && (
                <div>
                    <div>
                        <form encType="multipart/form-data" id='editForm' onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <input type="text" value={this.state.contentName} onChange={(e) => this.handleChange(e,'contentName')} className="form-control" placeholder="Content Name" />
                                <small id="nameDesc" className={this.checkInput(this.state.contentName) ? 'form-text text-muted' : 'form-text invalid'} >This is the name of the folder that will be created in Box</small>
                            </div>
                            <div className="form-group">
                                <input type="text" value={this.state.contentDesc}  onChange={(e) => this.handleChange(e,'contentDesc')}  className="form-control" placeholder="Content Description" />
                                <small id="desc" className={this.checkInput(this.state.contentDesc) ? 'form-text text-muted' : 'form-text invalid'}>A concise description of the content in this folder</small>
                            </div>
                            <div className="form-group">
                                <select value={this.state.contentLOB} onChange={(e) => this.handleChange(e,'contentLOB')} >
                                    <option value="Featured">Featured</option>
                                    <option value="Starter">Starter</option>
                                    <option value="Vertical">Vertical</option>
                                    <option value="LOB">Line of Business</option>
                                </select>
                                <small id="desc" className="form-text text-muted">The sorting bucket for the content: Featured, Starter, LOB</small>
                            </div>
                            <div className="form-group">
                                <input type="text" value={this.state.contentImg}  onChange={(e) => this.handleChange(e,'contentImg')} className="form-control" placeholder="Image URL" />
                                <small id="desc" className={this.checkInput(this.state.contentImg) ? 'form-text text-muted' : 'form-text invalid'}>The image that will be displayed on the content card</small>
                            </div>
                        </form>
                    </div>

                    <ContentExplorer token={this.state.token} rootFolderId={this.state.newFolderID} logoUrl='https://cdn4.iconfinder.com/data/icons/money/512/1-512.png' language='en-US' messages={messages} />
                    <form encType="multipart/form-data" onSubmit={this.getFolderSize}>
                        <button onSubmit={this.getFolderSize} disabled={this.checkSubmitStatus()} className="btn btn-primary">Commit Changes</button>
                        <button onClick={this.handleBackButton} className='btn btn-warning float-right'>Back</button>
                    </form>
                </div>
            )}
            </div>
        )}
}