import React from 'react';
import '../styles/buttons.css';

const credentials = require('../client-credentials/credentials');
const stagingCredentials = require('../client-credentials/credentials-staging')
const devCredentials = require('../client-credentials/credentials-dev')

// Production redirect url and client ID
const prodClientID = credentials.BoxSDK.clientID;
const prodRedirectURL = credentials.redirectURL;

// Staging redirect url and client ID
const stagingClientID = stagingCredentials.BoxSDK.clientID;
const stagingRedirectURL = stagingCredentials.redirectURL;

// Development redirect url and client ID
const devClientID = devCredentials.BoxSDK.clientID;
const devRedirectURL = devCredentials.redirectURL;

// Production and dev redirect URL's
const productionAuthEndpoint = `https://account.box.com/api/oauth2/authorize?response_type=code&client_id=${prodClientID}&redirect_uri=${prodRedirectURL}&state=my-security-token`;

const stagingAuthEndpoint = `https://account.box.com/api/oauth2/authorize?response_type=code&client_id=${stagingClientID}&redirect_uri=${stagingRedirectURL}&state=my-security-token`;
const devAuthEndpoint = `https://account.box.com/api/oauth2/authorize?response_type=code&client_id=${devClientID}&redirect_uri=${devRedirectURL}&state=my-security-token`;

const LoginButton = () => {
    // Check for production environment variable
    if(process.env.REACT_APP_NODE_ENV === 'production') {
        return (
            <a className="loginButton" href={productionAuthEndpoint}>Sign In</a>
        );
    }
    if(process.env.REACT_APP_NODE_ENV === 'staging'){
        return (
        <a className="loginButton" href={stagingAuthEndpoint}>Sign In Staging</a>
        );
    }
    else {
        return (
            <a className="loginButton" href={devAuthEndpoint}>Sign In Dev</a>
        );
    }
}

export default LoginButton