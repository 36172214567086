import React, { Component } from 'react';
import '../styles/analytics.css';

export default class Analytics extends Component {
    constructor(props) {
        super(props);

        this.state = {
            contentTransfered: 0,
            totalUsers: 0,
            topTenUsers: [],
            historyArray: [],
            term: ''
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleHistory = this.handleHistory.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }

    // Get the total amount downloaded from Redis
    componentDidMount() {
        fetch('/api/analytics/total', {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            }
          }).then((response) => {
              if(response.status === 200) {
                  const contentData = response.json();

                  contentData.then((content) => {
                      this.setState({contentTransfered: content.totalSize, totalUsers: content.totalUsers});
                  });
              }
          });
    }

    // Get the top ten users and clear any history we have been looking at
    handleSubmit() {
        this.setState({topTenUsers: [], historyArray: []});
        fetch('/api/analytics/topten', {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            }
          }).then((response) => {
              if(response.status === 200) {
                  const contentData = response.json();

                  contentData.then((content) => {
                    content.topTenArray.forEach((item) => {
                        this.setState({topTenUsers: [...this.state.topTenUsers, item]});
                    });
                  });
              }
          });
    }

    // API request to get the history of one user being searched for
    handleHistory(email) {
        this.setState({historyArray: []})
        fetch('/api/downloadHistory', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                currentEmail: email
            })
            }).then((response) => {
                if(response.status === 200) {
                    const contentData = response.json();

                    const historyItems = [];
                    contentData.then((content) => {
                        for(let i = 0; i < content.history.length; i+=2) {
                            historyItems.push(
                                <div key={i} className='dataPoint'>{content.history[i+1]} : {content.history[i]}</div>
                            );
                        }
                        this.setState({historyArray: historyItems});
                    })
                }
            });
    }

    handleSearchChange(term) {
        this.setState({term});
    }

    handleSearch() {
        fetch('/api/downloadHistory', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                currentEmail: this.state.term
            })
            }).then((response) => {
                if(response.status === 200) {
                    const contentData = response.json();

                    const historyItems = [];
                    contentData.then((content) => {
                        if(content.history.length <= 0) {
                            historyItems.push(<div key='1' className='dataPoint'>User <span className='userError'>{this.state.term}</span> not found</div>);
                        }
                        else {
                            for(let i = 0; i < content.history.length; i+=2) {
                                historyItems.push(
                                    <div key={i} className='dataPoint'>{content.history[i+1]} : {content.history[i]}</div>
                                );
                            }
                        }
                        this.setState({historyArray: historyItems});
                    })
                }
            });
    }

    render() {
        return (
            <div className="analyticsContainer">
            {/* Expecting incoming data to be in MB so dividing by 1000 to get GB for totalsize */}
                <div className='dataPoint'>Total content transfered: {parseInt(this.state.contentTransfered)/1000} GB</div>
                <div className='dataPoint'>Total users: {this.state.totalUsers}</div>
                <button className='btn btn-outline-info' onClick={this.handleSubmit}>Get top 10 users</button>
                <br />
                <input type='text' onChange={event => this.handleSearchChange(event.target.value)} value={this.state.tern} className='form-control userSearch'></input>
                <button onClick={this.handleSearch} className='btn btn-outline-info searchButton'>Search User</button>
                <ul id='dataSection' className="list-group list-group-flush">
                {
                    this.state.topTenUsers.map((item) => {
                        return(
                            <li className="list-group-item deleteCard" key={item[0]}>
                            {/* We divide the download count by two because the list containing the downloads has the date and the item downloaded, so we need to get rid of the dates */}
                                <span><span>{item[0]}</span> - <span>{parseInt(item[1])/2} downloads</span></span>
                                <button onClick={() => this.handleHistory(item[0])} className='btn btn-outline-info moreButton'>History</button>
                            </li>
                        );
                    })
                }
                </ul>
                <div className='historyData'>
                    <p>Download History</p>
                    {this.state.historyArray.length > 0 &&
                        <div>
                            {this.state.historyArray}
                        </div>
                    }
                </div>
            </div>
        )
    }
}