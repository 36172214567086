//This component shows the history of downloads tied to a user once the user is logged in at the / url
import React, { Component } from 'react';
import '../styles/downloadHistory.css';

export default class DownloadHistory extends Component {

  constructor() {
    super();

    this.state = {historyArray: []};

  }

  // Make API request to get the download history from Redis
  componentDidMount() {
    fetch('/api/downloadHistory', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            currentEmail: this.props.currentEmail
        })
      }).then((response) => {
          if(response.status === 200) {
              const contentData = response.json();

              contentData.then((content) => {
                content.history.forEach((item) => {
                    // Load each item into the historyArray array
                    this.setState({historyArray: [...this.state.historyArray, item]});
                })
              });
          }
      });
    }

    render() {
        // Create new array to hold a list of li elements with content name and date/time downloaded included
        const historyItems = [];
        // Iterate over the historyArray state and increment by two
        // Increment by two because the format each user's history is stored in Redis as is Content Name, Date/Time of download
        for(let i = 0; i < this.state.historyArray.length; i+=2) {
            historyItems.push(
                <li className="list-group-item"><span className='contentTitleHistory'>{this.state.historyArray[i+1]}</span><span className='contentDate'>{this.state.historyArray[i]}</span> </li>
            );
        }
      
        return(
            <div>
                <span className='historyTitle'>Download History</span>
                <div className='historyContainer'>
                    <div className="card">
                        {historyItems.length > 0 &&
                            <ul className="list-group list-group-flush">
                                {historyItems}
                            </ul>
                        }

                        {historyItems.length <= 0 && 
                            <p>No download history</p>
                        }
                    </div>
                </div>
            </div>
        );
    }
}