// Credentials file for client side
module.exports = {
    BoxSDK: {
        clientID: "pwtbwfqpe25yx4vbhdxtdr7x489w1uan",
        clientSecret: "oMJoNxaH4N8uVgyp6hM9hWgQc4Dr2XOS",
        enterpriseID: "65809755"

    },
    redirectURL: 'https://www.thetreasury.app/home',
    adminEmail: 'admin+treasuryappnode@demoeng.com',
    cookieName: 'treasuryCookie',
    encryptionKey: '1dka#sb8',
    destFolder: '52467386025',
    contentFolder: '51568087484'
  }