import React from 'react';

function Navbar(props) {

    // Navbar for the admin console, will assign a class to active button and send data to the dashboard component as to what component to display
    return (
    <div className="btn-group navButtons" role="group" aria-label="First group">
        <button onClick={() => props.handleClick('Add')} className= {props.contentOptionClass === 'Add' ? 'btn btn-sm btn-info' : 'btn btn-sm btn-outline-info'} type="button">Add Content</button>
        <button onClick={() => props.handleClick('Edit')} className= {props.contentOptionClass === 'Edit' ? 'btn btn-sm btn-info' : 'btn btn-sm btn-outline-info'} type="button">Edit Content</button>
        <button onClick={() => props.handleClick('Delete')} className= {props.contentOptionClass === 'Delete' ? 'btn btn-sm btn-info' : 'btn btn-sm btn-outline-info'} type="button">Remove Content</button>
        <button onClick={() => props.handleClick('Analytics')} className= {props.contentOptionClass === 'Analytics' ? 'btn btn-sm btn-info' : 'btn btn-sm btn-outline-info'} type="button">Analytics</button>
        <button onClick={() => props.handleClick('Reset')} className= {props.contentOptionClass === 'Reset' ? 'btn btn-sm btn-info' : 'btn btn-sm btn-outline-info'} type="button">Reset Download Count</button>
    </div>  
    );
}

export default Navbar;