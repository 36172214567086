import React, { Component } from "react";
import { Route, Router } from "react-router-dom";
import Cookies from "universal-cookie";
import App from "./App";
import Home from "./Home";
import Dashboard from "./admin/Dashboard";
import history from "./history";
import crypto from "crypto-js";

import Credentials from "./client-credentials/credentials";

const cookies = new Cookies();

class Routes extends Component {
  constructor() {
    super();

    this.state = { authStatus: false, email: "" };

    this.checkAuth = this.checkAuth.bind(this);
    this.returnAuthToLoggedOut = this.returnAuthToLoggedOut.bind(this);
  }

  //Get the cookie and decrypt it to see if anyone has tampered with it in the browser
  //This gives us persistence of state in the app by checking if the cookie is valid every time we refresh or go to a new page
  componentDidMount() {
    let payload = cookies.get(Credentials.cookieName);

    if (payload !== undefined) {
      //We see that there is a cookie for this app in the browser
      let bytes = crypto.AES.decrypt(payload, Credentials.encryptionKey);
      try {
        let unencryptedPayload = bytes.toString(crypto.enc.Utf8);
        var payloadJSON = JSON.parse(unencryptedPayload);
      } catch (e) {
        console.log("Error in decrypting the cookie: " + e);
        payloadJSON = `{"status": ${false}, "email": ""}`;
      }

      this.setState({
        authStatus: payloadJSON.status,
        email: payloadJSON.email,
      });
    }
  }

  //Check the authorization status by decrypting the cookie
  checkAuth(status) {
    if (status === true) {
      let payload = cookies.get(Credentials.cookieName);

      if (payload !== undefined) {
        let bytes = crypto.AES.decrypt(payload, Credentials.encryptionKey);
        let unencryptedPayload = bytes.toString(crypto.enc.Utf8);
        let payloadJSON = JSON.parse(unencryptedPayload);

        //If we can decrypt the cookie and read our status parameter, we are authenticated and no one has tampered with the cookie
        this.setState({
          authStatus: payloadJSON.status,
          email: payloadJSON.email,
        });
      }
    }
    //Don't even try to decrypt the cookie, we have failed in another component
    else {
      return false;
    }
  }

  // Return auth status to logged out
  returnAuthToLoggedOut() {
    this.setState({ authStatus: false, email: "" });
  }

  render() {
    return (
      <Router history={history}>
        <div>
          <Route
            path="/"
            render={(props) => (
              <App
                visible={this.state.authStatus}
                authStatus={this.state.authStatus}
                auth={this.returnAuthToLoggedOut}
                email={this.state.email}
                {...props}
              />
            )}
          />
          <Route
            path="/home"
            render={(props) => (
              <Home
                cookies={cookies}
                authStatus={this.state.authStatus}
                email={this.state.email}
                checkAuth={this.checkAuth}
                {...props}
              />
            )}
          />
          {this.state.authStatus === true &&
            this.state.email === Credentials.adminEmail && (
              <Route path="/adminconsole" render={(props) => <Dashboard />} />
            )}
        </div>
      </Router>
    );
  }
}

export default Routes;
