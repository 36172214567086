import React, { Component } from 'react';

import { ContentExplorer } from 'box-ui-elements';
import messages from 'box-ui-elements/i18n/en-US';
import 'box-ui-elements/dist/explorer.css';

import '../styles/addContent.css';

export default class AddContent extends Component {
    constructor() {
        super();

        this.state = {
            token: '', // Token value for Box Content Explorer's
            contentName: '', // Input value for content name
            contentDesc: '', // Input value for content description
            contentImg: '', // Input value for content image
            contentLOB: 'Featured', // Input value for type of content
            folderCreated: false, // Status to let us know when folder has been created
            newFolderID: '', // New folder ID once it has been created in Box
            folderLoading: false, // Status to let us know when the folder is beinf created in Box
            message: 'Loading...' // Message to display when folder being created
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.checkSubmitStatus = this.checkSubmitStatus.bind(this);
        this.requestDownscopedToken = this.requestDownscopedToken.bind(this);
        this.getFolderSize = this.getFolderSize.bind(this);
    }

    // Basic function to check if the input is blank or not in a field below
    checkInput(item) {
        if(item.length <= 0) {
            return false;
        }
        return true;
    }

    // Function to check to see if any required fields are blank
    // Used to activate the submit button once everything is done
    checkSubmitStatus() {
        let {contentName, contentDesc, contentImg} = this.state;
        if(contentName.length <= 0 || contentDesc.length <= 0 || contentImg.length <= 0) {
            return true;
        }
        return false;
    }

    // Function to handle updating the state of each individual input item
    handleChange(e, field) {
        if(field === 'contentName') {
            this.setState({contentName: e.target.value})
        }
        else if(field === 'contentDesc') {
            this.setState({contentDesc: e.target.value})
        }
        else if(field === 'contentImg') {
            this.setState({contentImg: e.target.value})
        }
        else if(field === 'contentLOB'){
            this.setState({contentLOB: e.target.value})
        }
    }

    // API request to get a downscoped token for the folder we want to view
    // One of the body params specifies service as the type, meaning we get a token that lets us upload and delete content
    requestDownscopedToken(folderPath) {
        return new Promise((resolve, reject) => {
            fetch('/api/exchangeToken', {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    folderPath: folderPath,
                    type: 'service'
                })
              }).then((response) => {
                  let tokenReceived = response.json();
                  tokenReceived.then((token) => {
                      resolve(token.newToken);
                })
            });
        })
    }

    // API request to server to perform Redis operations and create box folder
    handleSubmit(e) {
        e.preventDefault();
        this.setState({folderLoading: true})

        fetch('/api/addContent', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                contentOption: this.state.contentLOB,
                contentName: this.state.contentName,
                contentDesc: this.state.contentDesc,
                contentImg: this.state.contentImg
            })
          }).then((response) => {
              if(response.status === 200) {
                  const contentData = response.json();
        
                  contentData.then((content) => {
                      if(content !== undefined) {
                      this.requestDownscopedToken(content).then((token) => {
                            this.setState({token: token, folderCreated: true, newFolderID: content, folderLoading: false});
                      })
                    }
                  });
              }

              else {
                  this.setState({ message: 'Error: ' + response.status })
              }
          });
    }

    // Function to get folder size after uploading has been done
    getFolderSize(e) {
        e.preventDefault();
        console.log('getting size');

        fetch('/api/getFolderSize', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                folderID: this.state.newFolderID,
                contentName: this.state.contentName
            })
          }).then((response) => {
              if(response.status === 200) {
                  const sizeData = response.json();
        
                  sizeData.then((fileSize) => {
                      if(fileSize.size !== undefined) {
                        console.log(fileSize.size.size);
                        console.log(fileSize.size.size/1000000)
                       }
                    });
              }
          });
    }

    render() {
        return (
            <div>
                <div id='addFormContainer'>
                    <form encType="multipart/form-data" id='addForm' onSubmit={this.handleSubmit}>
                        <div className="form-group">
                            <input type="text" value={this.state.contentName} onChange={(e) => this.handleChange(e,'contentName')} className="form-control" placeholder="Content Name" />
                            <small id="nameDesc" className={this.checkInput(this.state.contentName) ? 'form-text text-muted' : 'form-text invalid'} >This is the name of the folder that will be created in Box</small>
                        </div>
                        <div className="form-group">
                            <input type="text" value={this.state.contentDesc}  onChange={(e) => this.handleChange(e,'contentDesc')}  className="form-control" placeholder="Content Description" />
                            <small id="desc" className={this.checkInput(this.state.contentDesc) ? 'form-text text-muted' : 'form-text invalid'}>A concise description of the content in this folder</small>
                        </div>
                        <div className="form-group">
                            <select onChange={(e) => this.handleChange(e,'contentLOB')} >
                                <option value="Featured">Featured</option>
                                <option value="Starter">Starter</option>
                                <option value="Vertical">Vertical</option>
                                <option value="LOB">Line of Business</option>
                            </select>
                            <small id="desc" className="form-text text-muted">The sorting bucket for the content: Featured, Starter, LOB</small>
                        </div>
                        <div className="form-group">
                            <input type="text" value={this.state.contentImg}  onChange={(e) => this.handleChange(e,'contentImg')} className="form-control" placeholder="Image URL" />
                            <small id="desc" className={this.checkInput(this.state.contentImg) ? 'form-text text-muted' : 'form-text invalid'}>The image that will be displayed on the content card</small>
                        </div>
                        <button onSubmit={this.handleSubmit} className="btn btn-primary" disabled={this.checkSubmitStatus()}>Submit</button>
                        <span className={this.state.folderLoading ? 'loadingFolder' : 'hideIndicator'}>{this.state.message}</span>
                    </form> 
                </div>
            <br />
            { this.state.folderCreated === true && (
                    <div>
                        <ContentExplorer token={this.state.token} rootFolderId={this.state.newFolderID} logoUrl='https://cdn4.iconfinder.com/data/icons/money/512/1-512.png' language='en-US' messages={messages} />
                        <form enctype="multipart/form-data" onSubmit={this.getFolderSize}>
                            <button onSubmit={this.getFolderSize} className="btn btn-primary">Commit Changes</button>
                        </form>
                    </div>
                )
            }
            <br />
            </div>
        )
    }
}