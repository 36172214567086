import React, { Component } from 'react';
import TemplateListItem from './TemplateListItem';
import Toolbar from './toolbar/Toolbar';
import './styles/templatelist.css';
import './styles/toastr.css';

// Local content database
import ContentList from './contentList';
import Credentials from './client-credentials/credentials';

// Folder where folder is copied and then deleted after transfer
const destFolder = Credentials.destFolder;

let container;

//Main container for the content items
export default class TemplateList extends Component {
    constructor(props) {
        super(props);

        this.state={
            contentEntries: ContentList,
            search: '',
            toolbarButtonValue: localStorage.getItem('filterTab') || 'Featured',
            downloadEntries: {},
            downloadEntriesReady: false
        };

        this.updateTerm = this.updateTerm.bind(this);
        this.updateToolbarButton = this.updateToolbarButton.bind(this);
    }

    // Function to see if searchItem matches what is in the search bar currently
    performSearch(searchItem) {
        return searchItem.toLowerCase().indexOf(this.state.search.toLowerCase());
    }

    // Get the download data from our local static DB and load it into the downloadEntries object
    componentDidMount() {
        fetch('/api/localdb/all', {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          }).then((response) => {
                const downloadData = response.json();

                downloadData.then((downloadItem) => {
                    // DownloadEntiresReady is used to render the cards once we have the data loaded
                    this.setState({ downloadEntries: downloadItem.localDownloadStore, downloadEntriesReady: true });
                });
          });
    }

    // Function to Render the template list items that contain the content
    renderTemplateListItem(contentObject) {
        return (
            <TemplateListItem
                cardTitle={contentObject.Name}
                titleImage={contentObject.ImgURL}
                description={contentObject.Desc}
                sourceFolder={contentObject.ID}
                destFolder={destFolder}
                size={contentObject.Size}
                downloaded={this.state.downloadEntries[contentObject.Name]}
                email={this.props.email}
                selectedCard={this.props.selectedCard}
                contentLOB={contentObject.LOB}
                WIP={contentObject.WIP}
            />
        )
    }

    // Function to update the search term in the state object
    updateTerm(term) {
        this.setState({search: term});
    }

    // Function to update the search filter in the toolbar dropdown
    updateToolbarButton(value) {
        this.setState({toolbarButtonValue: value});
    }

    toast(type, title, content) {
        if (type === 'success') {
            container.success(title, content);
        }

        else if(type === 'clear') {
            container.clear();
            container = null;
        }
    }

    render() {
        return(
            <div>
                <Toolbar updateTerm={this.updateTerm} updateToolbar={this.updateToolbarButton} />
                <div className="columns">
                {
                    this.state.contentEntries.map((contentItem, index) => {
                        // If contentItem is not loaded, give an error message (used mostly when we got this data from Redis)
                        if(contentItem === null) {
                            return <div key={index}>Error in loading content</div>
                        }

                        // If the filter is set to all, we only have to check to see what matches the search bar
                        else if(this.state.toolbarButtonValue === 'All') {
                            if(this.performSearch(contentItem.Name) !== -1) {
                                return (
                                    <ul key={contentItem.Name}>
                                        {/* Make sure that we don't see a flash when the downloads get loaded on the card */}
                                        {this.state.downloadEntriesReady && this.renderTemplateListItem(contentItem)}
                                    </ul>
                                );
                            }
                            // Return null if search finds nothing so that React does not get mad
                            else {
                                return null;
                            }
                        }
                        // If we are already filtered with the tookbar button and want to search as well
                        else {
                            if(this.performSearch(contentItem.Name) !== -1 && contentItem.LOB === this.state.toolbarButtonValue) {
                                return (
                                    <ul key={contentItem.Name} style={{borderRadius:'25px !important'}}>
                                    {this.state.downloadEntriesReady &&
                                      this.renderTemplateListItem(contentItem)}
                                  </ul>
                                );
                            }
                            else {
                                return null;
                            }
                        }
                    })
                } 
                </div>  
            </div>
        );
    }
}