import React, { Component } from 'react';
import '../styles/deleteContent.css';

const CONTENT_FOLDER = '51568087484'; 

export default class DeleteContent extends Component {
    constructor(props) {
        super(props);

        this.state = {contentEntries: []};
    }

    componentDidMount() {
        fetch('/api/resolveContent', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                contentFolderID: CONTENT_FOLDER
            })
          }).then((response) => {
              if(response.status === 200) {
                  const contentData = response.json();

                  contentData.then((content) => {
                      content.items.forEach((item) => {
                          this.setState({contentEntries: [...this.state.contentEntries, item]});
                      })
                  });
              }
          });
    }

    handleSubmit(item) {
        this.state.contentEntries.find((object, index) => {
            if(object.Name === item.Name) {
                let newArr = [...this.state.contentEntries];
                newArr.splice(index, 1);
                this.setState({contentEntries: newArr});
                return index;
            }
            return false;
        });
        fetch('/api/deleteContent', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                itemName: item.Name,
                folderID: item.ID
            })
          }).then((response) => {
              if(response.status === 200) {
              }
          });
    }

    render() {
        return (
            <div className="deleteContainer">
                <ul className="list-group list-group-flush">
                {
                    this.state.contentEntries.map((item) => {
                        return(
                            <li className="list-group-item deleteCard" key={item.Name}>
                                <img className='refImage' src={item.ImgURL} alt='cardContent'></img> {item.Name} - <span>{item.LOB}</span>
                                <button onClick={() => this.handleSubmit(item)} className='btn btn-outline-danger deleteButton'>X</button>
                            </li>
                        );
                    })
                }
                </ul>
            </div>
        )
    }
}