import React, { Component } from "react";
import LoginButton from "./signin_signout/LoginButton";
import LogoutButton from "./signin_signout/LogoutButton";
import DownloadHistory from "./download-history/DownloadHistory";
import WelcomeScreen from "./splashscreen/welcomeScreen";
import "./styles/navbar.css";
import BoxTreasuryImage from "./styles/boxTreasury.JPG";

import Credentials from "./client-credentials/credentials";

// The admin email address that can access the admin dashboard
const adminEmail = Credentials.adminEmail;

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.revokeAuthStatus = this.revokeAuthStatus.bind(this);
    this.navBarRenderAdmin = this.navBarRenderAdmin.bind(this);
    this.navBarRender = this.navBarRender.bind(this);
  }

  // Call the returnAuthToLoggedOut() function in routes.js to remove the auth status once Logout button pressed
  revokeAuthStatus() {
    this.props.auth();
  }

  // Function to render the admin console version of the navbar
  // Has email on left side of navbar and adds extra button to go to Admin Dashboard
  navBarRenderAdmin(Component, statusProp) {
    return (
      <nav className="navbar navbar-light bg-light navbar-expand-md">
        <div className="navbar-collapse collapse w-100 order-1 order-md-0 dual-collapse2">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item active">
              <a className="nav-link" href="/">
                {this.props.email}
              </a>
            </li>
          </ul>
        </div>
        <div className="mx-auto order-0">
          <a className="navbar-brand mx-auto titleName" href="/home">
            The Treasury
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target=".dual-collapse2"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
        <div className="navbar-collapse collapse w-100 order-3 dual-collapse2">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a href="/adminconsole" id="dashboardButton">
                Admin Dashboard
              </a>
            </li>
            <li className="nav-item">
              <Component.type status={statusProp} />
            </li>
          </ul>
        </div>
      </nav>
    );
  }

  //Function to render the normal navbar
  navBarRender(Component, statusProp) {
    return (
      <nav
        className={
          this.props.visible
            ? "navbar navbar-light bg-light navbar-expand-md"
            : "zeroVisibility"
        }
      >
        <div className="navbar-collapse collapse w-100 order-1 order-md-0 dual-collapse2">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item active">
              <a href="/home">
                <img
                  className="treasuryLogoNav"
                  src={BoxTreasuryImage}
                  alt="Treasury"
                ></img>
              </a>
            </li>
          </ul>
        </div>
        <div className="mx-auto order-0">
          {/* <a className="navbar-brand mx-auto" href="/home">The Treasury</a> */}
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target=".dual-collapse2"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
        <div className="navbar-collapse collapse w-100 order-3 dual-collapse2">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a className="nav-link emailDisplay" href="/">
                {this.props.email}
              </a>{" "}
              <Component.type status={statusProp} />
            </li>
          </ul>
        </div>
      </nav>
    );
  }

  render() {
    // If we are logged in and currently the admin
    if (this.props.authStatus === true && this.props.email === adminEmail) {
      return (
        <div>
          {this.navBarRenderAdmin(<LogoutButton />, this.revokeAuthStatus)}
          {
            // If we are wanting to see the download history
            window.location.pathname === "/" && (
              <DownloadHistory currentEmail={this.props.email} />
            )
          }
        </div>
      );
    }

    // If we are logged inbut not the admin
    else if (this.props.authStatus === true) {
      return (
        <div>
          {this.navBarRender(<LogoutButton />, this.revokeAuthStatus)}
          {window.location.pathname === "/" && (
            <DownloadHistory currentEmail={this.props.email} />
          )}
        </div>
      );
    }

    // If we are logged out
    else {
      return (
        <div>
          {this.navBarRender(<LoginButton />, null)}
          {
            // Make sure we see the welcome screen even if we logout from somewhere besides /home
            window.location.pathname !== "/home" && <WelcomeScreen />
          }
        </div>
      );
    }
  }
}

export default App;
