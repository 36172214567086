import React from 'react';
import '../styles/welcomeScreen.css';
import LoginButton from '../signin_signout/LoginButton';

import DELogo from '../styles/DELogo.png';
import BoxLogo from '../styles/boxlogo.jpg';

export default function() {
    return (
         <section className="mainContainer">
            <div className="left-half-welcome">
            <img className='deLogo' src={DELogo} alt='DE Logo'></img>
            <div className='triangle-left'></div>
                <article>
                    <p className='welcome-description'>Automatically transfer quality content into your Box account</p>
                </article>
            </div>
            <div className="right-half-welcome">
                <article>
                    <img className='treasuryLogo' src={BoxLogo} alt='box treasury'></img>
                    <LoginButton />
                </article>
            </div>
        </section>
    )
}