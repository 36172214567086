import HR from './contentImages/HR.svg';
import IT from './contentImages/IT.svg';
import BoxLegal from './contentImages/Legal.svg';
import Consulting from './contentImages/Consulting.svg';
import Marketing from './contentImages/Marketing.svg';
import Sales from './contentImages/Sales.svg';
import StarterKit from './contentImages/DemoStarterKit.svg';
import BusinessDocs from './contentImages/SampleBusinessDocs.svg';
import ITAR from './contentImages/ITAR.svg';


const destFolder = '52467386025'; //Folder where everything is copied then deleted

function sortingFunction(a, b) {
    return (a.WIP > b.WIP) ? 1 : ((b.WIP > a.WIP) ? -1 : 0);
}

export default [
        // {
        //     Name: 'Microsoft Docs',
        //     ImgURL: MicrosoftDocs,
        //     Desc: 'Microsoft Documents!',
        //     ID: '63326316501',
        //     destfolder: destFolder,
        //     Size: 0,
        //     LOB: 'Starter',
        //     DownloadCount: 0
        // },
        // {
        //     Name: 'Video',
        //     ImgURL: Documents,
        //     Desc: 'Video content',
        //     ID: '64099459903',
        //     destfolder: destFolder,
        //     Size: 375,
        //     LOB: 'Starter',
        //     DownloadCount: 0
        // },
        // {
        //     Name: 'Retail',
        //     ImgURL: Retail,
        //     Desc: 'Sourcing, manufacturing, and selling products',
        //     ID: '63318830885',
        //     destfolder: destFolder,
        //     Size: 280.36,
        //     LOB: 'Vertical',
        //     DownloadCount: 0
        // },
        // {
        //     Name: 'Financial Services',
        //     ImgURL: FinancialServices,
        //     Desc: 'Leverage Box for wealth management, commercial lending, and deal rooms',
        //     ID: '63327603330',
        //     destfolder: destFolder,
        //     Size: 327.83,
        //     LOB: 'Vertical',
        //     DownloadCount: 0
        // },
        // {
        //     Name: '3D Content',
        //     ImgURL: DContent,
        //     Desc: 'Sample 3D images and 360 preview files',
        //     ID: '63319966220',
        //     destfolder: destFolder,
        //     Size: 0.25,
        //     LOB: 'Starter',
        //     DownloadCount: 0
        // },
        // {
        //     Name: 'AEC',
        //     ImgURL: AEC,
        //     Desc: 'No more shimmying down a steel beam back to the trailer to gather you blue prints, this is Box for AEC',
        //     ID: '63319427877',
        //     destfolder: destFolder,
        //     Size: 637.47,
        //     LOB: 'Vertical',
        //     DownloadCount: 0
        // },
        // {
        //     Name: 'Life Sciences',
        //     ImgURL: LifeSciences,
        //     Desc: 'Use Box for Life Sciences to better collaborate, share data, research, and improve care delivery',
        //     ID: '63324507857',
        //     destfolder: destFolder,
        //     Size: 189.26,
        //     LOB: 'Vertical',
        //     DownloadCount: 0
        // },
        // {
        //     Name: 'Watermarking',
        //     ImgURL: Watermarking,
        //     Desc: 'Help deter unauthorized re-sharing of sensitive information in your Box account with Watermarking',
        //     ID: '63329009299',
        //     destfolder: destFolder,
        //     Size: 12.89,
        //     LOB: 'Starter',
        //     DownloadCount: 0
        // },
        {
            Name: 'Box for HR',
            ImgURL: HR,
            Desc: 'Streamline your customer on-boarding process and easily share new hire documents with employees',
            ID: '63162543440',
            destfolder: destFolder,
            Size: 0.25,
            LOB: 'LOB',
            DownloadCount: 0,
            WIP: true
        },
        {
            Name: 'Box for Consulting',
            ImgURL: Consulting,
            Desc: 'Leverage Box as a knowledge base and client management tool for client interactions',
            ID: '63174701053',
            destfolder: destFolder,
            Size: 2.40,
            LOB: 'LOB',
            DownloadCount: 0,
            WIP:true
        },
        // {
        //     Name: 'Healthcare',
        //     ImgURL: Healthcare,
        //     Desc: 'Includes images, documents, and folder structures tailored for physicians and clinics',
        //     ID: '63323858415',
        //     destfolder: destFolder,
        //     Size: 295.14,
        //     LOB: 'Vertical',
        //     DownloadCount: 0
        // },
        {
            Name: 'Box for IT',
            ImgURL: IT,
            Desc: 'Securely store and share IT knowledge assets within your company',
            ID: '63162277554',
            destfolder: destFolder,
            Size: 775.93,
            LOB: 'LOB',
            DownloadCount: 0,
            WIP:true
        },
        // {
        //     Name: 'Non-Profit',
        //     ImgURL: Nonprofit,
        //     Desc: 'Enable sharing files and collaborating on an organizations most impactful ideas',
        //     ID: '63325755113',
        //     destfolder: destFolder,
        //     Size: 91.32,
        //     LOB: 'Vertical',
        //     DownloadCount: 0
        // },
        {
            Name: 'Demo Starter Kit',
            ImgURL: StarterKit,
            Desc: 'Everything you need to give a basic Box demo',
            ID: '63173595404',
            destfolder: destFolder,
            Size: 228.98,
            LOB: 'Featured',
            DownloadCount: 0,
            WIP:false
        },
        // {
        //     Name: 'Advertising',
        //     ImgURL: Advertising,
        //     Desc: 'Enable ad agencies to pitch, collaborate, and get approval on new ad campaigns with their clients',
        //     ID: '63323061900',
        //     destfolder: destFolder,
        //     Size: 707.80,
        //     LOB: 'Vertical',
        //     DownloadCount: 0
        // },
        // {
        //     Name: 'Collaboration Starter Kit',
        //     ImgURL: Collaboration,
        //     Desc: 'Create a lived-in feel in your Box demo environment. Programmatically create users, groups, collaborations, and tasks',
        //     ID: '63314612818',
        //     destfolder: destFolder,
        //     Size: 6.34,
        //     LOB: 'Featured',
        //     DownloadCount: 0
        // },
        // {
        //     Name: 'Productivity Docs',
        //     ImgURL: Documents,
        //     Desc: 'Google, Microsoft and Apple sample productivity docs',
        //     ID: '63157531562',
        //     destfolder: destFolder,
        //     Size: 1.05,
        //     LOB: 'Featured',
        //     DownloadCount: 0
        // },
        // {
        //     Name: 'Legal', 
        //     ImgURL: Legal,
        //     Desc: 'With Box, top law firms collaborate and share sensitive materials simply and safely',
        //     ID: '63319544650',
        //     destfolder: destFolder,
        //     Size: 277.18,
        //     LOB: 'Vertical',
        //     DownloadCount: 0
        // },
        // {
        //     Name: 'Sports',
        //     ImgURL: Sports,
        //     Desc: 'Manage organization scouting, sponsorships, media, internal strategy, and more',
        //     ID: '63315975162',
        //     destfolder: destFolder,
        //     Size: 663.53,
        //     LOB: 'Vertical',
        //     DownloadCount: 0
        // },
        // {
        //     Name: 'Sample Files',
        //     ImgURL: Documents,
        //     Desc: 'Sample Box Notes, MS Office files, images, videos, PDFs, and more',
        //     ID: '63163768526',
        //     destfolder: destFolder,
        //     Size: 745.34,
        //     LOB: 'Starter',
        //     DownloadCount: 0
        // },
        {
            Name: 'Box for Legal',
            ImgURL: BoxLegal,
            Desc: 'Improve service and optimize workflow efficency for your legal operations team',
            ID: '63178469042',
            destfolder: destFolder,
            Size: 168.86,
            LOB: 'LOB',
            DownloadCount: 0,
            WIP:true
        },
        // {
        //     Name: 'Oil & Gas',
        //     ImgURL: Oil,
        //     Desc: 'Drastically change how Oil & Gas organizations collaborate and function',
        //     ID: '63321085908',
        //     destfolder: destFolder,
        //     Size: 760.46,
        //     LOB: 'Vertical',
        //     DownloadCount: 0
        // },
        {
            Name: 'Box for Sales',
            ImgURL: Sales,
            Desc: 'From managing customer presentations to taking meeting notes',
            ID: '63174790823',
            destfolder: destFolder,
            Size: 534.13,
            LOB: 'LOB',
            DownloadCount: 0,
            WIP:true
        },
        {
            Name: 'Box for Marketing',
            ImgURL: Marketing,
            Desc: 'Push content updates in real-time and gain visibility into what collateral is most effective',
            ID: '63159770099',
            destfolder: destFolder,
            Size: 571.14,
            LOB: 'LOB',
            DownloadCount: 0,
            WIP:true
        },
        {
            Name: 'Sample Business Documents',
            ImgURL: BusinessDocs,
            Desc: 'Sample Business Docments',
            ID: '191663665126',
            destfolder: destFolder,
            Size: 39.99,
            LOB: 'Starter',
            DownloadCount: 0,
            WIP:true
        },
        {
            Name: 'ITAR',
            ImgURL: ITAR,
            Desc: 'Security, Governance and Compliance',
            ID: '191681491256',
            destfolder: destFolder,
            Size: 179.87,
            LOB: 'Featured',
            DownloadCount: 0,
            WIP:false
        }
].sort(sortingFunction);