import React, { Component } from 'react';

import '../styles/resetButton.css';

export default class ResetDownload extends Component {
    constructor() {
        super();
    
        this.state = {message: ''};
        this.handleReset = this.handleReset.bind(this);
      }

      handleReset() {
        fetch('/api/localdb/reset', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            }).then((response) => {
                console.log(response)
                if(response.status === 200) {
                    this.setState({ message: 'Reset complete' });
                }
                
            });
    }
      render() {
        return (
            <div className='resetDownloadContainer'>
            <div>
                <p>Clicking this button will reset the download count to what the real download count is in Redis. Use this after pusing a new update to Heroku, or when and if the Dyno restarts</p>
            </div>
                <button onClick={this.handleReset}className='btn btn-info'>Reset Download</button><span>{this.state.message}</span>
            </div>
    
        )
      }
}