import React, { Component } from "react";
import { ToastContainer } from "react-toastr";

import "./styles/App.css";
import "./styles/animate.css";
import png from "./styles/construction.png"

export default class TemplateListItem extends Component {
  constructor(props) {
    super(props);

    this.container = null;

    this.state = {
      transfered: false,
      inProgress: false,
      message: "",
      buttonText: "Transfer",
      imageLoading: true,
      token: "",
      localDownloadCount: 0,
    };

    this.transferContent = this.transferContent.bind(this);
    this.removeTimer = this.removeTimer.bind(this);

    this.handleViewClick = this.handleViewClick.bind(this);
    this.handleImageLoaded = this.handleImageLoaded.bind(this);

    this.toast = this.toast.bind(this);
  }

  handleViewClick() {
    this.props.selectedCard({
      name: this.props.cardTitle,
      desc: this.props.description,
      rootFolderId: this.props.sourceFolder,
      size: this.props.size,
      downloaded: this.props.downloaded,
      image: this.props.titleImage,
    });
  }

  // Timer to remove the transfered status after the button has been pressed
  removeTimer() {
    setTimeout(() => {
      this.setState({ transfered: false });
    }, 3000);
  }

  // API call to back end to transfer the selected content
  transferContent() {
    this.setState({
      inProgress: true,
      buttonText: "Loading",
      localDownloadCount: this.state.localDownloadCount + 1,
    });
    fetch("/api/content", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sourceFolder: this.props.sourceFolder,
        userEmail: this.props.email,
        destFolder: this.props.destFolder,
        contentName: this.props.cardTitle,
      }),
    }).then((response) => {
      if (response.status === 200) {
        this.setState({
          transfered: true,
          inProgress: false,
          message: "Success",
          buttonText: "Transfer",
        });
        this.removeTimer();
        this.toast(
          "success",
          this.props.cardTitle,
          "Successfully transferred!"
        );
      } else {
        try {
          const responseJSON = response.json();
          responseJSON.then((respjson) => {
            console.log(respjson.error);
            this.setState({
              transfered: true,
              inProgress: false,
              message: "Error",
              buttonText: "Transfer",
            });
            this.removeTimer();
            this.toast("error", respjson.error, "Error with transfer!");
          });
        } catch (e) {
          console.log("unauthorized token change");
          this.toast("error", e, "Error with transfer!");
        }
      }
    });
  } 

  handleImageLoaded() {
    this.setState({ imageLoading: false });
  }

  toast(type, title, content) {
    if (type === "success") {
      this.container.success(title, content, {
        closeButton: true,
        timeOut: "1500",
      });
    } else if (type === "clear") {
      this.container.clear();
    } else if (type === "error") {
      this.container.error(title, content, { closeButton: true });
    }
  }    
  

  render() {
    return (
      <div style={{ borderRadius: "25px !important" }}>
        {this.props.WIP ? (
          <div style={{ borderRadius: "25px !important" }}>
            <div style={{display:'flex', justifyContent:'center'}}>
              <img 
                style={{
                  position: "absolute",
                  zIndex: 1,
                  height: "70px",
                  width: "70px",
                  marginTop:' 2%',
                }}
                src={png}></img>
            </div>
            <div
              className="shadow p-3 mb-5 rounded contentCard overlay"
              style={{ borderRadius: "25px !important" }}>
              <div className="container">
                <ToastContainer
                  ref={(ref) => (this.container = ref)}
                  className="toast-bottom-right"
                />
              </div>
              <div className="contentInfo">
                {this.props.size} MB{" "}
                <span className="float-right downloaded">
                  <i className="fas fa-arrow-alt-circle-down"></i>{" "}
                  {parseInt(this.props.downloaded, 10) +
                    this.state.localDownloadCount}
                </span>
              </div>
              <div className="imageContainer">
                <img
                  onLoad={this.handleImageLoaded}
                  src={
                    this.state.imageLoading
                      ? "https://i.pinimg.com/originals/3f/2c/97/3f2c979b214d06e9caab8ba8326864f3.gif"
                      : this.props.titleImage
                  }
                  alt="Card"></img>
                <div className="contentTitle">{this.props.cardTitle}</div>
              </div>
              <div></div>
              <div className="cardButtons">
                <button
                  onClick={this.handleViewClick}
                  disabled={true}
                  type="submit"
                  className="controlButton viewColor">
                  View
                </button>
                <button
                  type="submit"
                  disabled={true}
                  className="controlButtonTransfer transferColor float-right"
                  onClick={this.transferContent}>
                  {!this.state.inProgress ? (
                    this.state.buttonText
                  ) : (
                    <span
                      className={
                        this.state.inProgress ? "spinnerView" : "transferedView"
                      }>
                      {" "}
                      {this.state.transfered ? this.state.message : null}
                    </span>
                  )}
                </button>
                {/* <span className={this.state.inProgress ? "spinner" : "transfered"}> {this.state.transfered ? this.state.message : null}</span> */}
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{ borderRadius: "25px !important" }}
            className="shadow p-3 mb-5 rounded contentCard">
            <div className="container">
              <ToastContainer
                ref={(ref) => (this.container = ref)}
                className="toast-bottom-right"
              />
            </div>
            <div className="contentInfo">
              {this.props.size} MB{" "}
              <span className="float-right downloaded">
                <i className="fas fa-arrow-alt-circle-down"></i>{" "}
                {parseInt(this.props.downloaded, 10) +
                  this.state.localDownloadCount}
              </span>
            </div>
            <div className="imageContainer">
              <img
                onLoad={this.handleImageLoaded}
                src={
                  this.state.imageLoading
                    ? "https://i.pinimg.com/originals/3f/2c/97/3f2c979b214d06e9caab8ba8326864f3.gif"
                    : this.props.titleImage
                }
                alt="Card"></img>
              <div className="contentTitle">{this.props.cardTitle}</div>
            </div>
            <div></div>
            <div className="cardButtons">
              <button
                onClick={this.handleViewClick}
                type="submit"
                className="controlButton viewColor">
                View
              </button>
              <button
                type="submit"
                disabled={this.state.inProgress}
                className="controlButtonTransfer transferColor float-right"
                onClick={this.transferContent}>
                {!this.state.inProgress ? (
                  this.state.buttonText
                ) : (
                  <span
                    className={
                      this.state.inProgress ? "spinnerView" : "transferedView"
                    }>
                    {" "}
                    {this.state.transfered ? this.state.message : null}
                  </span>
                )}
              </button>
              {/* <span className={this.state.inProgress ? "spinner" : "transfered"}> {this.state.transfered ? this.state.message : null}</span> */}
            </div>
          </div>
        )}
      </div>
    );
  }
}
