import React, { Component } from 'react';

import Search from '../search/Search';
import '../styles/toolbar.css';

export default class ToolBar extends Component {
    constructor() {
        super();

        // Which button we should start out on when coming to app
        this.state = {activeButton: localStorage.getItem('filterTab') || 'Featured'};
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(value) {
        // Update state to reflect to user and update props to use in search functions in TemplateList
        this.setState({activeButton: value});
        // Update state of TemplateList immediatly by using props
        this.props.updateToolbar(value);
        // Update localstorage for after we exit the view tab
        localStorage.setItem('filterTab', value);
    }

    render() {
        return(
            <div className='toolBarWrapper'>
                <Search updateTerm={this.props.updateTerm} />
                <div className="dropdown">
                    <button className="btn toolbarButton dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {this.state.activeButton}
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        {/* Pass the selection onto the handleClick function */}
                        <span onClick={() => this.handleClick('All')} className="dropdown-item" >All</span>
                        <span onClick={() => this.handleClick('Featured')} className="dropdown-item" >Featured</span>
                        <span onClick={() => this.handleClick('Starter')} className="dropdown-item">Starter</span>
                        <span onClick={() => this.handleClick('Vertical')} className="dropdown-item">Vertical</span>
                        <span onClick={() => this.handleClick('LOB')} className="dropdown-item">Line of Business</span>
                    </div>
                </div>
            </div>
        )
    }

}