import React, { Component } from 'react';
import '../styles/searchbar.css';

class Search extends Component {
    constructor() {
        super();

        this.state = {term: ''};
        this.handleChange = this.handleChange.bind(this);
    }

    // Update the search term to show in state and also update it to props
    handleChange(term) {
        this.setState({ term });
        this.props.updateTerm(term);
    }

    render() {
        return (
            <span className='searchWrapper'>
                <i className="fas fa-search"></i><input className='searchBar'
                type='text'
                onChange={event => this.handleChange(event.target.value)}
                value={this.state.term} />
            </span>

        );
    }
}

export default Search;