import React, { Component } from 'react';
import AddContent from './AddContent';
import DeleteContent from './DeleteContent';
import EditContent from './EditContent';
import Analytics from './Analytics';
import Navbar from './Navbar';
import ResetDownload from './ResetDownload';

import '../styles/adminDashboard.css';


export default class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {contentOption: 'Add'};

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        this.setState({contentOption: e});
    }

    render() {
        const {contentOption} = this.state;
        if(contentOption === 'Add') {
            return (
                <div className="row">
                    <div className="col-md-2">
                    </div>
                    <div className="col-md-8">
                        <Navbar handleClick={this.handleClick} contentOptionClass={this.state.contentOption}/>
                        <AddContent />
                    </div>
                </div>
            );
        }
        else if(contentOption === 'Edit') {
            return (
                <div className="row">
                    <div className="col-md-2">
                    </div>
                    <div className="col-md-8">
                        <Navbar handleClick={this.handleClick} contentOptionClass={this.state.contentOption}/>
                        <EditContent />
                    </div>
                        <div className="col-md-2">
                    </div>
                </div>
            );
        }
        else if(contentOption === 'Delete') {
            return (
                <div className="row">
                    <div className="col-md-2">
                    </div>
                    <div className="col-md-8">
                        <Navbar handleClick={this.handleClick} contentOptionClass={this.state.contentOption}/>
                        <DeleteContent />
                    </div>
                        <div className="col-md-2">
                    </div>
                </div>
            );
        }
        else if(contentOption === 'Analytics') {
            return (
                <div className="row">
                    <div className="col-md-2">
                    </div>
                    <div className="col-md-8">
                        <Navbar handleClick={this.handleClick} contentOptionClass={this.state.contentOption}/>
                        <Analytics />
                    </div>
                        <div className="col-md-2">
                    </div>
                </div>
            );
        }
        else if(contentOption === 'Reset') {
            return (
                <div className="row">
                    <div className="col-md-2">
                    </div>
                    <div className="col-md-8">
                        <Navbar handleClick={this.handleClick} contentOptionClass={this.state.contentOption}/>
                        <ResetDownload />
                    </div>
                        <div className="col-md-2">
                    </div>
                </div>
            );
        }
    }
}