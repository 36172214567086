import React, { Component } from "react";
import TemplateList from "./TemplateList";
import crypto from "crypto-js";
import WelcomeScreen from "./splashscreen/welcomeScreen";
import LoggingInImage from "./styles/loggingIn.gif";

import { ToastContainer } from "react-toastr";

import { ContentExplorer } from "box-ui-elements";
import messages from "box-ui-elements/i18n/en-US";
import "box-ui-elements/dist/explorer.css";

import "./styles/home.css";

import Credentials from "./client-credentials/credentials";

class Home extends Component {
  constructor(props) {
    super(props);

    // failedLogin needed as a state check to wait for the API
    // to return a result and to finish setting cookie before displaying new home page
    this.state = {
      failedLogin: false,
      cardSelected: false,
      cardTitle: "",
      cardDesc: "",
      token: "",
      rootFolder: "",
      cardImage: "",
      transfered: false,
      inProgress: false,
      message: "",
      buttonText: "Transfer",
    };
    this.getClient = this.getClient.bind(this);
    this.passCardData = this.passCardData.bind(this);
    this.requestDownscopedToken = this.requestDownscopedToken.bind(this);
    this.transferContent = this.transferContent.bind(this);
  }

  // Check to see if the domain is allowed
  checkDomain(domain) {
    // Get the domain of the email address used to sign in
    let parsedDomain = domain.replace(/.*@/, "");
    if (
      parsedDomain !== "box.com" &&
      parsedDomain !== "demoeng.com" &&
      parsedDomain !== "boxdemo.com"
    ) {
      this.props.checkAuth(false);
      return false;
    }
    return true;
  }

  // Set the encrypted cookie after the domain has been verified and user has logged in successfully
  setEncryptedCookie(fulfiledUser) {
    // Encrypt the cookie to be used
    let payload =
      "" +
      crypto.AES.encrypt(
        JSON.stringify({ status: true, email: fulfiledUser }),
        Credentials.encryptionKey
      );
    // Set the cookie in the browser
    this.props.cookies.set(Credentials.cookieName, payload, { maxAge: 3600 });
    // Send successful signal back to routes to have it decrypt the cookie and have it ready to be used by other components
    this.props.checkAuth(true);
    // Get rid of the code after we use it in the browser without refreshing the page
    window.history.pushState(null, "Home", "/home");
  }

  // Transfer content function, need to rewrite to pass this to template list item
  transferContent() {
    this.setState({
      inProgress: true,
      buttonText: "Loading",
      localDownloadCount: this.state.localDownloadCount + 1,
    });
    fetch("/api/content", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sourceFolder: this.state.rootFolder,
        userEmail: this.props.email,
        destFolder: Credentials.destFolder,
        contentName: this.state.cardTitle,
      }),
    }).then((response) => {
      if (response.status === 200) {
        this.setState({
          transfered: true,
          inProgress: false,
          message: "Success",
          buttonText: "Transfer",
        });
        this.removeTimer();
        this.toast(
          "success",
          this.state.cardTitle,
          "Successfully transferred!"
        );
      } else {
        try {
          const responseJSON = response.json();
          responseJSON.then((respjson) => {
            console.log(respjson.error);
            this.setState({
              transfered: true,
              inProgress: false,
              message: "Error",
              buttonText: "Transfer",
            });
            this.removeTimer();
            this.toast("error", respjson.error, "Error with transfer!");
          });
        } catch (e) {
          console.log("unauthorized token change");
          this.toast("error", e, "Error with transfer!");
        }
      }
    });
  }

  removeTimer() {
    setTimeout(() => {
      this.setState({ transfered: false });
    }, 3000);
  }

  // Function to get the auth code and token
  getClient(code) {
    fetch("/api/token", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        code: code, //Code found in the URL from OAuth process after user grants access
      }),
    }).then((response) => {
      try {
        //check to make sure valid JSON response
        
        const usrData = response.json();
        usrData.then((fulfiled) => {
          //Get the login used from Box API and see if it matches our whitelisted domains
          if (this.checkDomain(fulfiled.user.login) === false) {
            this.setState({ failedLogin: true });
            console.log("Domain is not box.com or demoeng.com");
            return;
          } else {
            this.setEncryptedCookie(fulfiled.user.login);
          }
        });
      } catch (e) {
        this.setState({ failedLogin: true });
        this.props.checkAuth(false);
      }
    });
  }
  componentDidMount() {
    //Get the auth code and send it to the API to get a persistent client
    const url = window.location.href;
    //Could not find the OAuth code in the browser, send us back to home
    if (url.search("code") === -1) {
      window.history.pushState(null, "Home", "/home");
      this.props.checkAuth(false);
      this.setState({ failedLogin: true });
    } else {
      //Get the client code from the URL
      const initialPos = url.search("code") + 5;
      const code = url.substring(initialPos, initialPos + 32);
      //Contact our backend to verify the user and make sure domain is whitelisted
      this.getClient(code);
    }
  }

  // API request to get the token needed for the content explorer
  requestDownscopedToken(folderPath) {
    this.setState({ modalIsOpen: true });
    fetch("/api/exchangeToken", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        folderPath: folderPath,
      }),
    }).then((response) => {
      let tokenReceived = response.json();
      tokenReceived.then((token) => {
        this.setState({ token: token.newToken });
      });
    });
  }

  // Function to get selected card data and display it on the home screen
  passCardData(cardInfo) {
    this.requestDownscopedToken(cardInfo.rootFolderId);
    this.setState({
      cardSelected: true,
      cardTitle: cardInfo.name,
      cardDesc: cardInfo.desc,
      rootFolder: cardInfo.rootFolderId,
      cardImage: cardInfo.image,
    });
  }

  toast(type, title, content) {
    if (type === "success") {
      this.container.success(title, content, {
        closeButton: true,
        timeOut: "1500",
      });
    } else if (type === "clear") {
      this.container.clear();
    } else if (type === "error") {
      this.container.error(title, content, { closeButton: true });
    }
  }

  render() {
    // If we are not authenticated yet, the URL still has the OAuth code in it, and we have
    // Not ruled if it is a failed attempt, tell the user we are loading the home page
    if (
      !this.props.authStatus &&
      window.location.search.length > 0 &&
      !this.state.failedLogin
    ) {
      return (
        <div className="loadingScreen">
          <img
            className="loadingImage"
            src={LoggingInImage}
            alt="logging in"
          ></img>{" "}
          <br />
        </div>
      );
    }

    // If we are logged in succesfully load the templatelist component which loads the content cards
    else if (this.props.authStatus && !this.state.cardSelected) {
      return (
        <div>
          <TemplateList
            authStatus={this.props.authStatus}
            email={this.props.email}
            selectedCard={this.passCardData}
          />
        </div>
      );
    }

    // If we have selected a card to view, clear the screen and show it
    else if (this.props.authStatus && this.state.cardSelected) {
      return (
        <div>
          <div className="container">
            <ToastContainer
              ref={(ref) => (this.container = ref)}
              className="toast-bottom-right"
            />
          </div>
          <div className="split left">
            <img
              className="imgContainerView"
              src={this.state.cardImage}
              alt="Content display"
            ></img>
            <div className="cardInfoView">
              <h3 className="contentTitleView">{this.state.cardTitle}</h3>
              <p style={{ textAlign: "center", fontFamily: "lato" }}>
                {this.state.cardDesc}
              </p>
              <span style={{display: 'flex', justifyContent: 'space-evenly',marginTop:'5%'}}>
                <button
                  className="buttonBackView"
                  onClick={() => this.setState({ cardSelected: false })}>
                  Back
                </button>

              <button
                type="submit"
                disabled={this.state.inProgress}
                className="buttonTransferView float-right"
                onClick={this.transferContent}
              >
                {!this.state.inProgress ? (
                  "Transfer"
                ) : (
                  <span
                    className={
                      this.state.inProgress ? "spinnerView" : "transferedView"
                    }
                  >
                    {" "}
                    {this.state.transfered ? this.state.message : null}
                  </span>
                )}
              </button>
              </span>
            </div>
          </div>
          <div className="split right">
            {this.state.token.length !== 0 && (
              <div className="uiElement">
                <ContentExplorer
                  token={this.state.token}
                  rootFolderId={this.state.rootFolder}
                  logoUrl='box'
                  language="en-US"
                  messages={messages}
                  contentPreviewProps={{
                   
                  }}
                />
              </div>
            )}
            {this.state.token.length === 0 && (
              <div className="loaderContainer">
                <div className="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    }

    // If we are not logged in and are not actively trying to login, show the welcome page
    else {
      return <WelcomeScreen />;
    }
  }
}

export default Home;
