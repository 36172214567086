import React from 'react';
import Cookies from 'universal-cookie';
import Credentials from '../client-credentials/credentials';

import '../styles/logoutButton.css';

const cookies = new Cookies();

const LogoutButton = (props) => {

    function handleClick() {
        // Remove the cookie from the browser
        cookies.remove(Credentials.cookieName);
        // Revoke auth status by calling function passed in props from App.js
        props.status(cookies.get(Credentials.cookieName));
        // Reset the localstorage so that we can show featured content the next time someone logs in
        localStorage.removeItem('filterTab');
    }

    return (
        <button type="submit" className="controlButtonLogout logoutColor" onClick={handleClick}>Logout</button>
    );
}

export default LogoutButton;